




























































































































import { defineComponent, ref, toRefs, watch } from '@vue/composition-api'
import moment from 'moment'
import { showError, endpoints, toSnakeCase, toCamelCase } from 'utils'
import { api } from 'plugins'

const AddMoreSalesDialog = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    assign: {
      type: Object,
      required: false,
      default: undefined
    },
    customer: {
      types: Object || undefined,
      required: true
    }
  },
  setup(props, { emit, root }) {
    const { $toast } = root
    const { assign } = toRefs(props)
    const assignShowDialog = ref<any>({ ...assign.value })
    const check = ref('pdf')
    const date = ref<string>()
    const auctionDateModal = ref(false)
    const currentIndex = ref<number>(0)
    const salesResultBreakDownList = ref([] as any)
    const isAddMore = ref(true)
    // const salesCreate = ref<any>({})
    const salesDeleteIds = ref<number[]>([])

    const close = () => {
      return emit('on-close', false)
    }
    const goToDate = () => {
      if (isAddMore.value) salesResultBreakDownList.value[currentIndex.value].salesDate = date.value
      else assign.value.salesResult.salesResultBreakdowns[currentIndex.value].salesDate = date.value
    }

    const addSalesDateBreakDown = () => {
      salesResultBreakDownList.value.push({
        salesBoxes: props.assign.boxes,
        assignment: props.assign.id,
        salesDate: moment(new Date()).format('YYYY-MM-DD'),
        salesStems: 0,
        salesAmount: 0,
        versionSalesResult: 0
      })
    }

    const deleteOne = (index: any, mode: string) => {
      if (mode === 'add') salesResultBreakDownList.value.splice(index, 1)
      if (mode === 'edit') {
        currentIndex.value = index
        salesDeleteIds.value.push(
          assignShowDialog.value.salesResult.salesResultBreakdowns[index].id
        )
        assignShowDialog.value.salesResult.salesResultBreakdowns.splice(index, 1)
        // console.log(salesDeleteIds.value)
      }
    }

    const openDateDialog = (index: number, mode: string) => {
      isAddMore.value = mode === 'add'
      if (isAddMore.value) {
        currentIndex.value = index
        date.value = salesResultBreakDownList.value[currentIndex.value].salesDate
      } else {
        date.value = props.assign.salesResult.salesResultBreakdowns[index].salesDate
      }
      auctionDateModal.value = true
    }

    const updateAndCreateSales = async () => {
      const body = {
        delete: salesDeleteIds.value,
        create: salesResultBreakDownList.value,
        update: assignShowDialog.value.salesResult.salesResultBreakdowns
          ? assignShowDialog.value.salesResult.salesResultBreakdowns
          : [],
        versionSalesResult: props.assign.salesResult.version,
        salesResultId: props.assign.salesResult.id,
        assignmentId: props.assign.id
      }
      console.log(body)
      if (body.update.length === 0 && body.create.length === 0 && body.delete.length === 0) {
        $toast.info(root.$t('sales.add_more_sales.no_sales_are_changed'))
        close()
        return
      }
      // console.log(body)
      try {
        const { data } = await api.post(
          `${endpoints.SALES_RESULT_BREAKDOWN}group_update`,
          toSnakeCase(body)
        )
        if (data.messages) $toast.info(root.$t('common.msg.old_version'))
        else {
          emit('re-load', toCamelCase(data))
          $toast.success(root.$t('common.msg.update_success'))
          close()
        }
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.update_failed') as string)
      }
    }

    const onSave = async () => {
      // if yet add sales result
      // eslint-disable-next-line no-nested-ternary
      const isThereBreakdown = assign.value.salesResult.salesResultBreakdowns
        ? assign.value.salesResult.salesResultBreakdowns.length > 0
          ? assign.value.salesResult.salesResultBreakdowns[0].id
          : false
        : false
      if (salesResultBreakDownList.value.length === 0 && !isThereBreakdown) {
        $toast.error(root.$t('sales.add_more_sales.please_add_sales'))
        return
      }

      salesResultBreakDownList.value = salesResultBreakDownList.value.filter((e: any) => {
        if (e.salesStems === '') e.salesStems = 0
        if (e.salesAmount === '') e.salesAmount = 0
        return Number(e.salesAmount) !== 0 || Number(e.salesStems) !== 0
      })

      if (assignShowDialog.value.salesResult?.salesResultBreakdowns) {
        assignShowDialog.value.salesResult.salesResultBreakdowns =
          assignShowDialog.value.salesResult.salesResultBreakdowns.filter((e: any) => {
            if (e.salesStems === '') e.salesStems = 0
            if (e.salesAmount === '') e.salesAmount = 0
            return Number(e.salesStems) !== 0 || Number(e.salesAmount) !== 0
          })
      }

      await updateAndCreateSales()
    }

    watch(
      () => props.value,
      () => {
        if (props.value) {
          assignShowDialog.value = JSON.parse(JSON.stringify(assign.value))
          salesDeleteIds.value = []
          if (
            props.assign.salesResult.salesResultBreakdowns
              ? props.assign.salesResult.salesResultBreakdowns.length > 0
              : false
          ) {
            salesResultBreakDownList.value = [] as any
          } else {
            salesResultBreakDownList.value = [] as any
            addSalesDateBreakDown()
          }
        }
      }
    )
    return {
      check,
      date,
      auctionDateModal,
      close,
      goToDate,
      salesResultBreakDownList,
      addSalesDateBreakDown,
      deleteOne,
      openDateDialog,
      onSave,
      assignShowDialog
    }
  }
})

export default AddMoreSalesDialog
