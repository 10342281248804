













































































































































































































































































































































































































































































import { defineComponent, ref, onMounted, Ref, watch } from '@vue/composition-api'
import { StepButton, AddButton, ChooseNumber, LoaderComponent } from 'components'
import { api, framebus } from 'plugins'
import {
  endpoints,
  frameBusEvent,
  showError,
  toCamelCase,
  urlPath,
  toSnakeCase,
  convertToWidthCharacter,
  query,
  getClassOrderType
} from 'utils'
import { debounce, sumBy } from 'lodash'
import moment from 'moment'
import { Customer as CustomerRaw, Item, Variety } from 'typings'
import AddMoreSalesDialog from './AddMoreSalesDialog.vue'
import ImportCSVDialog from './ImportCSVDialog.vue'
import FixImport from './FixImport.vue'

interface SearchItem {
  id: number
  name: string
  text: string
  category: string
}

interface Category {
  name: string
  icon: string
  data: Array<SearchItem>
}

interface Record {
  boxes: number
  comment: string | null
  customerSalesImportId: number
  id: number
  price: number | null
  quantity: number
  size: string
  salesStems: number
  salesAmount: number
  productName: string
  status: number
}

const Sales = defineComponent({
  components: {
    StepButton,
    AddButton,
    AddMoreSalesDialog,
    ImportCSVDialog,
    ChooseNumber,
    LoaderComponent,
    FixImport
  },
  setup(props, { root }) {
    const { $toast, $store, $route, $router } = root
    const date = ref(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.params.date || moment(new Date()).format('YYYY-MM-DD')
    )
    const showConfirmDialog = ref(false)
    const isShowErrorMess = ref(false)
    const auctionDateModal = ref(false)
    const showBottomSheetBatch = ref(false)
    const showBottomSheetSingle = ref(false)
    const isAddMoreSaleDialog = ref(false)
    const isImportCSVDialog = ref(false)
    const isStartFix = ref(false)
    const isOpenList = ref(false)
    const showNumberDialog = ref(false)
    const titleNumberDialog = ref('')
    const titleNumberDialog2 = ref('')
    const titleBottomNumberDialog = ref('')
    const titleBottomNumberDialog2 = ref('')
    const totalDataSales = ref<any>({})
    const currentAssign = ref<any>()
    const currentCustomer = ref<any>()
    const searchInput = ref('')
    const listSearch = ref<SearchItem[]>([])
    const selectedList = ref<SearchItem[]>([])
    const onSearching = ref(false)
    const newKey = ref(1)
    const categoriesList = ref<Category[]>([])
    const isCalledDataHasOrderNum = ref(false)
    const itemsHasOrderNum: Ref<Array<Item>> = ref([])
    const varietiesHasOrderNum: Ref<Array<Variety>> = ref([])
    const customersHasOrderNum: Ref<Array<CustomerRaw>> = ref([])
    const loadingListSearch = ref(false)
    const listProperty = ['total_sales_child', 'total_price_child']
    const currentProperty = ref('')
    const countFinished = ref(0)
    const countUnfinished = ref(0)
    const itemsSelected = ref<number[]>([])
    const varietiesSelected = ref<number[]>([])
    const customersSelected = ref<number[]>([])
    const customers = ref<any[]>([])
    const searchedCustomer = ref<any[]>([])
    const selectItems = ref([
      { name: root.$t('common.all') as string, key: 0 },
      { name: root.$t('common.finished') as string, key: 1 },
      { name: root.$t('common.unfinished') as string, key: 2 }
    ])
    const selectedItem = ref(0)
    const currentInput = ref<number>(0)
    const initInput = ref<number | null>(0)
    const PROPERTIES_1 = ['total_sales_child', 'total_price_child']
    const PROPERTIES_2 = [
      'tax_amount',
      'sales_amount_with_tax',
      'usage_fee',
      'shipping_fee',
      'communication_fee',
      'balance_amount'
    ]
    const INPUT_2 = [
      'taxAmount',
      'salesAmountWithTax',
      'usageFee',
      'shippingFee',
      'communicationFee',
      'balanceAmount'
    ]
    const listErrorImport = ref<Array<Record>>([])
    const currentErrorIndex = ref(0)
    const getListSearch = async (input?: string): Promise<void> => {
      newKey.value += 1
      let itemAPI = `${endpoints.ITEMS}has_order_num`
      let varietyAPI = `${endpoints.VARIETIES}has_order_num`
      let customerAPI = `${endpoints.CUSTOMERS}has_order_num`
      if (input) {
        const inputValue = convertToWidthCharacter(input.toLowerCase(), 'full')
        itemAPI = `${endpoints.ITEMS}search_with?search_input=${inputValue}`
        varietyAPI = `${endpoints.VARIETIES}search_with?search_input=${inputValue}`
        customerAPI = `${endpoints.CUSTOMERS}search_with?search_input=${inputValue}`
      }
      listSearch.value = [...selectedList.value]
      loadingListSearch.value = true
      try {
        categoriesList.value = [
          { name: 'item', icon: 'mdi-flower', data: [] },
          { name: 'variety', icon: 'mdi-flower', data: [] },
          { name: 'customer', icon: 'mdi-home', data: [] }
        ]
        let responseItems = null
        let responseVarieties = null
        let responseCustomers = null
        if (!isCalledDataHasOrderNum.value || input) {
          responseItems = await api.get(itemAPI)
          responseVarieties = await api.get(varietyAPI)
          responseCustomers = await api.get(customerAPI)
          if (!isCalledDataHasOrderNum.value) {
            isCalledDataHasOrderNum.value = true
            itemsHasOrderNum.value = toCamelCase(responseItems?.data)
            varietiesHasOrderNum.value = toCamelCase(responseVarieties?.data)
            customersHasOrderNum.value = toCamelCase(responseCustomers?.data)
          }
        }
        const itemsList = input ? toCamelCase(responseItems?.data) : itemsHasOrderNum.value
        categoriesList.value.push()
        itemsList.forEach((item: Item) => {
          const newItem = {
            id: item.id,
            name: item.name,
            text: item.name,
            category: 'item'
          }
          let searchResult: any = null
          const isExist = selectedList.value.find(
            (e) => e.category === 'item' && e.id === newItem.id
          )
          const categoryItemList = categoriesList.value.find((e) => e.name === 'item')?.data
          const isExistItem = categoryItemList?.find((e) => e.id === newItem.id)
          if (input) {
            const inputValue = convertToWidthCharacter(input.toLowerCase(), 'full')
            searchResult = item.searchStr
              .toLowerCase()
              .match(`^${inputValue}|\\|${inputValue}`)?.input
            if (searchResult) {
              if (!isExist) {
                listSearch.value.unshift(newItem)
              }
              if (!isExistItem) {
                categoriesList.value.find((e) => e.name === 'item')?.data.unshift(newItem)
              }
            } else if (item.searchStr.toLowerCase().includes(input.toLowerCase())) {
              if (!isExist) {
                listSearch.value.push(newItem)
              }
              if (!isExistItem) {
                categoriesList.value.find((e) => e.name === 'item')?.data.push(newItem)
              }
            }
          } else {
            if (!isExist) {
              listSearch.value.push(newItem)
            }
            if (!isExistItem) {
              categoriesList.value.find((e) => e.name === 'item')?.data.push(newItem)
            }
          }
        })
        const varietiesList = input
          ? toCamelCase(responseVarieties?.data)
          : varietiesHasOrderNum.value
        varietiesList.forEach((variety: Variety) => {
          const newVariety = {
            id: variety.id,
            name: variety.name,
            text: `${variety.item.name} ${variety.name}`,
            category: 'variety'
          }
          const isExist = selectedList.value.find(
            (e) => e.category === 'variety' && e.id === newVariety.id
          )
          const categoryVarietyList = categoriesList.value.find((e) => e.name === 'variety')?.data
          const isExistVariety = categoryVarietyList?.find((e) => e.id === newVariety.id)
          let searchResult: any = null
          if (input) {
            const inputValue = convertToWidthCharacter(input.toLowerCase(), 'full')
            searchResult = variety.searchStr
              .toLowerCase()
              .match(`^${inputValue}|\\|${inputValue}`)?.input
            if (searchResult) {
              if (!isExist && searchResult) {
                listSearch.value.unshift(newVariety)
              }
              if (!isExistVariety) {
                categoriesList.value.find((e) => e.name === 'variety')?.data.unshift(newVariety)
              }
            } else if (variety.searchStr.toLowerCase().includes(input.toLowerCase())) {
              if (!isExist) {
                listSearch.value.push(newVariety)
              }
              if (!isExistVariety) {
                categoriesList.value.find((e) => e.name === 'variety')?.data.push(newVariety)
              }
            }
          } else {
            if (!isExist && searchResult) {
              listSearch.value.push(newVariety)
            }
            if (!isExistVariety) {
              categoriesList.value.find((e) => e.name === 'variety')?.data.push(newVariety)
            }
          }
        })
        const customersList = input
          ? toCamelCase(responseCustomers?.data)
          : customersHasOrderNum.value
        customersList.forEach((customer: CustomerRaw) => {
          const newCustomer = {
            id: customer.id,
            name: customer.shortName ? customer.shortName : customer.name,
            text: `${customer.code ? customer.code : ''} ${
              customer.shortName ? customer.shortName : customer.name
            }`,
            category: 'customer'
          }
          const isExist = selectedList.value.find((e: any) => {
            return e.category === 'customer' && e.id === newCustomer.id
          })
          const categoryCustomerList = categoriesList.value.find((e) => e.name === 'customer')?.data
          const isExistCustomer = categoryCustomerList?.find((e) => e.id === newCustomer.id)
          let searchResult: any = null
          if (input) {
            const inputValue = convertToWidthCharacter(input.toLowerCase(), 'full')
            searchResult = customer.searchStr
              .toLowerCase()
              .match(`^${inputValue}|\\|${inputValue}`)?.input
            if (searchResult) {
              if (!isExist) {
                listSearch.value.unshift(newCustomer)
              }
              if (!isExistCustomer) {
                categoriesList.value.find((e) => e.name === 'customer')?.data.unshift(newCustomer)
              }
            } else if (customer.searchStr.toLowerCase().includes(input.toLowerCase())) {
              if (!isExist) {
                listSearch.value.push(newCustomer)
              }
              if (!isExistCustomer) {
                categoriesList.value.find((e) => e.name === 'customer')?.data.push(newCustomer)
              }
            }
          } else {
            if (!isExist) {
              listSearch.value.push(newCustomer)
            }
            if (!isExistCustomer) {
              categoriesList.value.find((e) => e.name === 'customer')?.data.push(newCustomer)
            }
          }
        })
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loadingListSearch.value = false
      }
    }

    const closeAddMoreSales = () => {
      isAddMoreSaleDialog.value = false
      showBottomSheetSingle.value = false
    }

    const closeSearch = () => {
      setTimeout(() => {
        onSearching.value = false
      }, 300)
    }

    const openFix = (errorRecords: Array<Record>) => {
      isStartFix.value = true
      listErrorImport.value = errorRecords
    }

    const updateListErrorRecord = (id: number, value: boolean) => {
      listErrorImport.value.forEach((record: any) => {
        if (record.id === id) {
          // eslint-disable-next-line no-param-reassign
          record.edited = value
        }
      })
    }

    const clearSearchValue = () => {
      searchInput.value = ''
      selectedList.value = []
      getListSearch()
    }

    const updateCagoriesList = debounce(async (input: string) => {
      if (input && input !== searchInput.value) {
        searchInput.value = input
        getListSearch(input)
      }
    }, 300)

    const getTotal = () => {
      const totalBoxs = sumBy(customers.value, 'salesBoxes')
      const totalStems = sumBy(customers.value, 'salesStems')
      const totalAmount = sumBy(customers.value, 'salesAmount')
      totalDataSales.value = {
        boxes: sumBy(customers.value, 'boxes'),
        stems: sumBy(customers.value, 'stems'),
        salesStems: totalStems,
        salesAmount: totalAmount,
        salesOneBoxPrice: Number(totalBoxs ? Number(totalAmount / totalBoxs).toFixed(0) : null),
        salesOneStemPrice: Number(totalStems ? Number(totalAmount / totalStems).toFixed(0) : null)
      }
      // count number of item finished / unfinished
      countUnfinished.value = customers.value.filter(
        (customer: any) => !customer.isCompleted
      ).length
      countFinished.value = customers.value.length - countUnfinished.value
    }

    const calculateData = (data: any) => {
      return data.map((customer: any) => {
        const salesResult = customer.assignments.map((assignment: any) => assignment.salesResult)
        const totalSalesBoxes = sumBy(salesResult, 'salesBoxes')
        const totalSalesStems = sumBy(salesResult, 'salesStems')
        const totalAmountSales = sumBy(salesResult, 'salesAmount')
        return {
          ...customer,
          boxes: sumBy(customer.assignments, 'boxes'),
          stems: sumBy(customer.assignments, 'stems'),
          salesStems: sumBy(
            customer.assignments.map((assignment: any) => assignment.salesResult),
            'salesStems'
          ),
          isCompleted: !customer.assignments.find((assign: any) => !assign.salesResult.isCompleted),
          salesBoxes: totalSalesBoxes,
          salesOneBoxPrice: totalSalesBoxes
            ? Number(Number(totalAmountSales / totalSalesBoxes).toFixed(0))
            : null,
          salesOneStemPrice: totalSalesStems
            ? Number(Number(totalAmountSales / totalSalesStems).toFixed(0))
            : null,
          salesAmount: totalAmountSales ? Number(Number(totalAmountSales).toFixed(0)) : null
        }
      })
    }

    const searchByStatus = () => {
      switch (selectedItem.value) {
        // All
        case 0:
          searchedCustomer.value = customers.value
          break
        case 1:
          // filter all assign with sales result completed
          searchedCustomer.value = customers.value.filter((customer: any) => customer.isCompleted)
          break
        case 2:
          searchedCustomer.value = customers.value
            .map((customer: any) => {
              const assignments = customer.assignments.filter(
                (assign: any) => !assign.salesResult.isCompleted
              )
              return assignments.length > 0 ? { ...customer, assignments } : false
            })
            .filter((customer: any) => customer)
          break
        default:
          break
      }
      searchedCustomer.value = calculateData(searchedCustomer.value)
    }

    const getData = async () => {
      const queryData = query.buildQuery({
        auction_date: date.value,
        item_ids: itemsSelected.value.length > 0 ? itemsSelected.value.toString() : '',
        variety_ids: varietiesSelected.value.length > 0 ? varietiesSelected.value.toString() : '',
        customer_ids: customersSelected.value.length > 0 ? customersSelected.value.toString() : ''
      })
      try {
        const { data } = await api.get(`${endpoints.SALES_RESULT}?${queryData}`)
        customers.value = toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
      customers.value = calculateData(customers.value)
      searchedCustomer.value = customers.value
      getTotal()
      searchByStatus()
    }

    const goToDate = async () => {
      countFinished.value = 0
      countUnfinished.value = 0
      $router
        .replace({
          name: urlPath.SALES.name,
          query: {
            date: date.value,
            called: 'true'
          }
        })
        .catch((err) => {
          // Ignore the vuex err regarding navigating to the page they are already on.
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            // But print any other errors to the console
            console.log(err)
          }
        })

      const latestSelectedDates = {
        auctionDate: date.value.toString(),
        packingDateRange: $store.state.common.latestSelectedDates.packingDateRange
          ? $store.state.common.latestSelectedDates.packingDateRange
          : [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
      }
      // eslint-disable-next-line no-restricted-globals
      // parent.postMessage(latestSelectedDates, '*')
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)
      await getData()
    }

    const updateDataBySales = async (newSalesResult: any) => {
      currentAssign.value.salesResult.version = newSalesResult.version
      const iCus = customers.value.findIndex(
        (customer: any) => customer.id === currentCustomer.value.id
      )
      const iAss = customers.value[iCus].assignments.findIndex(
        (assignment: any) => assignment.id === currentAssign.value.id
      )
      // update data
      customers.value[iCus].assignments[iAss].salesResult.isCompleted = newSalesResult.isCompleted
      customers.value[iCus].assignments[iAss].salesResult.salesAmount = newSalesResult.salesAmount
      customers.value[iCus].assignments[iAss].salesResult.id = newSalesResult.id
      customers.value[iCus].assignments[iAss].salesResult.salesBoxes = newSalesResult.salesBoxes
      customers.value[iCus].assignments[iAss].salesResult.salesStems = newSalesResult.salesStems
      customers.value[iCus].assignments[iAss].salesResult.salesResultBreakdowns =
        newSalesResult.salesResultBreakdowns
      customers.value[iCus].assignments[iAss].salesResult.version = newSalesResult.version
      customers.value[iCus].salesSummary = newSalesResult.salesSummary
      searchedCustomer.value = customers.value
      customers.value = calculateData(customers.value)
      searchByStatus()
      getTotal()
    }

    const updateDataBySummary = (newSalesSummary: any) => {
      const iCus = customers.value.findIndex(
        (customer: any) => customer.id === currentCustomer.value.id
      )
      customers.value[iCus].salesSummary = newSalesSummary
      customers.value = calculateData(customers.value)
      searchByStatus()
      getTotal()
    }

    const reloadAddMoreSale = (data: any) => {
      updateDataBySales(data)
    }

    // Edit sale result API
    const editSalesResultAPI = async (data: number) => {
      let body: any = {
        assignment: currentAssign.value.id,
        version: currentAssign.value.salesResult.version,
        salesBoxes: currentAssign.value.boxes,
        id: currentAssign.value.salesResult.id
      }
      if (currentProperty.value === 'total_sales_child') {
        body = {
          ...body,
          salesStems: data,
          salesAmount: currentAssign.value.salesResult.salesAmount
        }
      }
      if (currentProperty.value === 'total_price_child') {
        body = {
          ...body,
          salesAmount: data,
          salesStems: currentAssign.value.salesResult.salesStems
        }
      }
      // const breakdownId = salesResultBreakdownSelected.value?.id
      if (
        (data !== currentInput.value && data !== 0) ||
        (data === 0 && ![null, 0].includes(currentInput.value))
      ) {
        try {
          const res = (await api.post(`${endpoints.SALES_RESULT}`, toSnakeCase(body))).data
          if (res.messages) {
            $toast.info(root.$t('common.msg.old_version'))
            return false
          }
          await updateDataBySales(toCamelCase(res))
          $toast.success(root.$t('common.msg.update_success'))
        } catch (e) {
          showError(e, $toast, root.$t('common.msg.update_failed') as string)
        }
      }
      return true
    }

    // Edit Sale summary API
    const editSalesSummaryAPI = async (data: number) => {
      let body: any = {
        id: currentCustomer.value.salesSummary ? currentCustomer.value.salesSummary.id : null,
        customerId: currentCustomer.value.id,
        auctionDate: date.value
      }
      // if (currentCustomer.value.salesSummary?.id) body.id = currentCustomer.value.salesSummary.id
      if (currentProperty.value === 'tax_amount') body = { ...body, taxAmount: data }
      if (currentProperty.value === 'sales_amount_with_tax')
        body = { ...body, salesAmountWithTax: data }
      if (currentProperty.value === 'usage_fee') body = { ...body, usageFee: data }
      if (currentProperty.value === 'shipping_fee') body = { ...body, shippingFee: data }
      if (currentProperty.value === 'communication_fee') body = { ...body, communicationFee: data }
      if (currentProperty.value === 'balance_amount') body = { ...body, balanceAmount: data }
      if (
        (data !== currentInput.value && data !== 0) ||
        (data === 0 && ![null, 0].includes(currentInput.value))
      ) {
        try {
          const res = (await api.post(`${endpoints.SALE_SUMMARY}`, toSnakeCase(body))).data
          await updateDataBySummary(toCamelCase(res))
          $toast.success(root.$t('common.msg.update_success'))
        } catch (e) {
          showError(e, $toast, root.$t('common.msg.update_failed') as string)
        }
      }
    }

    const handleOpenNumberDialog = async (
      item: string,
      customer: any,
      assign: any,
      inputNumber: number
    ) => {
      currentInput.value = inputNumber
      currentAssign.value = assign
      currentCustomer.value = customer
      showNumberDialog.value = true
      currentProperty.value = item
      // init value for sales
      initInput.value = null
      if (PROPERTIES_1.includes(item)) {
        // if sales empty, init as current sales stems
        if (item === 'total_sales_child') {
          initInput.value = currentInput.value || assign.stems
        }
        titleNumberDialog.value = `${assign.orderType.name} ${assign.productName} ${assign.quality.name} ${assign.size.name} ${assign.quantity}${assign.unit.name} ${assign.boxType.name}`
        titleNumberDialog2.value = `合計: ${assign.boxes}ケース (${assign.stems}${assign.unit.name})   `
        titleBottomNumberDialog.value = `[${assign.customer.code}]${
          assign.customer.shortName ? assign.customer.shortName : assign.customer.name
        }`
        titleBottomNumberDialog2.value = moment(date.value).format(
          root.$t('common.time_month_date').toString()
        )
      }
      if (PROPERTIES_2.includes(item)) {
        titleNumberDialog.value = `[${customer.code}] ${
          customer.shortName ? customer.shortName : customer.name
        }`
        titleNumberDialog2.value = ''
        titleBottomNumberDialog2.value = ''
        titleBottomNumberDialog.value = moment(date.value).format(
          root.$t('common.time_month_date').toString()
        )
      }
    }

    const findNextAssign = (mode: string) => {
      // let assignments = searchedCustomer.value.map((customer: any) => customer.assignments)
      // assignments = [].concat(...assignments)
      const assignments = currentCustomer.value.assignments.filter((assignment: any) => {
        // add condition no breakdown
        return assignment.salesResult.salesResultBreakdowns
          ? assignment.salesResult.salesResultBreakdowns.length === 0
          : true
      })
      // find index current assign
      const currentIndex = assignments.findIndex(
        (assignment: any) => assignment.id === currentAssign.value.id
      )
      if (mode === 'next' && currentIndex + 1 === assignments.length) return false
      if (mode === 'prev' && currentIndex + 1 === 0) return false

      return mode === 'next' ? assignments[currentIndex + 1] : assignments[currentIndex - 1]
    }

    const editDataSales = async (action: string, data: number) => {
      // showNumberDialog.value = false
      if (PROPERTIES_1.includes(currentProperty.value)) {
        let pro = ''
        if (action === 'next') {
          // just call api when change data
          if (currentInput.value !== data) {
            const success = await editSalesResultAPI(data)
            if (!success) return
          }
          // get next property
          pro =
            listProperty.indexOf(currentProperty.value) === 0 ? listProperty[1] : listProperty[0]
          // if current property is amount => next assign
          if (listProperty.indexOf(currentProperty.value) === 1) {
            const nextAssign = findNextAssign('next')
            if (nextAssign) {
              currentAssign.value = nextAssign
              currentInput.value = currentAssign.value.salesResult.salesStems
            } else {
              // if this is last element => close dialog
              showNumberDialog.value = false
              return
            }
          } else currentInput.value = currentAssign.value.salesResult.salesAmount
        } else if (action === 'prev') {
          // just call api when change data
          if (currentInput.value !== data) {
            const success = await editSalesResultAPI(data)
            if (!success) return
          }
          // get next property
          pro =
            listProperty.indexOf(currentProperty.value) === 0 ? listProperty[1] : listProperty[0]
          // if current property is amount => next assign
          if (listProperty.indexOf(currentProperty.value) === 0) {
            // find index current assign
            const nextAssign = findNextAssign('prev')
            if (nextAssign) {
              currentAssign.value = nextAssign
              currentInput.value = currentAssign.value.salesResult.salesAmount
            } else {
              // if this is last element => close dialog
              showNumberDialog.value = false
              return
            }
          } else currentInput.value = currentAssign.value.salesResult.salesStems
        }
        // currentCustomer.value = findCurrentCustomerByAssignment()
        setTimeout(() => {
          handleOpenNumberDialog(
            pro,
            currentCustomer.value,
            currentAssign.value,
            currentInput.value
          )
        }, 20)
      }
      if (PROPERTIES_2.includes(currentProperty.value)) {
        const currentIndex = PROPERTIES_2.indexOf(currentProperty.value)
        const MAX_INDEX = PROPERTIES_2.length - 1
        if (action === 'next') {
          // just call api when change data
          if (currentInput.value !== data) await editSalesSummaryAPI(data)

          if (currentIndex < MAX_INDEX) {
            currentProperty.value = PROPERTIES_2[currentIndex + 1]
            currentInput.value = currentCustomer.value.salesSummary
              ? currentCustomer.value.salesSummary[INPUT_2[currentIndex + 1]]
              : 0
          }
          if (currentIndex === MAX_INDEX) {
            currentProperty.value = PROPERTIES_2.length ? PROPERTIES_2[0] : ''
            if (currentIndex + 1 <= MAX_INDEX) {
              currentInput.value = currentCustomer.value.salesSummary
                ? currentCustomer.value.salesSummary[INPUT_2[0]]
                : 0
            } else {
              showNumberDialog.value = false
              return
            }
          }
        } else if (action === 'prev') {
          // TODO:: save data
          // just call api when change data
          if (currentInput.value !== data) await editSalesSummaryAPI(data)
          if (currentIndex > 0 && currentIndex <= MAX_INDEX) {
            currentProperty.value = PROPERTIES_2[currentIndex - 1]
            currentInput.value = currentCustomer.value.salesSummary
              ? currentCustomer.value.salesSummary[INPUT_2[currentIndex - 1]]
              : 0
          }
          if (currentIndex === 0) {
            currentProperty.value = PROPERTIES_2[MAX_INDEX]
            if (currentIndex > 0 && currentIndex <= MAX_INDEX) {
              currentInput.value = currentCustomer.value.salesSummary
                ? currentCustomer.value.salesSummary[INPUT_2[MAX_INDEX]]
                : 0
            } else {
              showNumberDialog.value = false
              return
            }
          }
        }
        setTimeout(() => {
          handleOpenNumberDialog(
            currentProperty.value,
            currentCustomer.value,
            null,
            currentInput.value
          )
        }, 20)
      }
    }

    const getNextAuctionDate = async () => {
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}/next_auction_date`)
        date.value = data.data.date
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const onClickChildItemDotsIcon = (assign: any, customer: any) => {
      currentAssign.value = assign
      currentCustomer.value = customer
      showBottomSheetSingle.value = true
    }

    const openBreakDown = (assign: any, customer: any) => {
      currentAssign.value = assign
      currentCustomer.value = customer
      isAddMoreSaleDialog.value = true
    }

    // when click Import by CSV to open dialog
    const openImportCSV = () => {
      showBottomSheetBatch.value = false
      isImportCSVDialog.value = true
    }

    // to open list of imported detail (in start-fix box)
    const openListImport = () => {
      isImportCSVDialog.value = true
      isStartFix.value = false
      isOpenList.value = true
    }

    const filterAssigns = async () => {
      itemsSelected.value = selectedList.value
        .filter((selected: SearchItem) => {
          return selected.category === 'item'
        })
        .map((selected: SearchItem) => {
          return selected.id
        })
      varietiesSelected.value = selectedList.value
        .filter((selected: SearchItem) => {
          return selected.category === 'variety'
        })
        .map((selected: SearchItem) => {
          return selected.id
        })
      customersSelected.value = selectedList.value
        .filter((selected: SearchItem) => {
          return selected.category === 'customer'
        })
        .map((selected: SearchItem) => {
          return selected.id
        })
      await getData()
    }

    watch(selectedList, () => {
      searchInput.value = ''
      filterAssigns()
    })

    watch(onSearching, (val) => {
      searchInput.value = ''
      if (val) {
        getListSearch()
      }
    })

    onMounted(async () => {
      if (
        !(
          $store.state.common.latestSelectedDates.auctionDate &&
          $store.state.common.latestSelectedDates.packingDateRange
        )
      )
        await getNextAuctionDate()
      await getData()
    })

    return {
      date,
      auctionDateModal,
      showBottomSheetBatch,
      showBottomSheetSingle,
      isAddMoreSaleDialog,
      isImportCSVDialog,
      isStartFix,
      showNumberDialog,
      currentAssign,
      currentCustomer,
      handleOpenNumberDialog,
      goToDate,
      titleNumberDialog,
      titleNumberDialog2,
      editDataSales,
      totalDataSales,
      onClickChildItemDotsIcon,
      searchInput,
      listSearch,
      selectedList,
      closeSearch,
      onSearching,
      clearSearchValue,
      updateCagoriesList,
      newKey,
      loadingListSearch,
      categoriesList,
      titleBottomNumberDialog,
      titleBottomNumberDialog2,
      getData,
      countFinished,
      countUnfinished,
      selectItems,
      selectedItem,
      currentInput,
      initInput,
      currentProperty,
      searchByStatus,
      closeAddMoreSales,
      searchedCustomer,
      getClassOrderType,
      openBreakDown,
      reloadAddMoreSale,
      isShowErrorMess,
      showConfirmDialog,
      openImportCSV,
      openFix,
      openListImport,
      isOpenList,
      listErrorImport,
      currentErrorIndex,
      updateListErrorRecord
    }
  }
})

export default Sales
